export const couponContent = [
    {
        type: 'title',
        content: '进入大赛官网“个人中心”页面点击“开始比赛”，或者在进入腾讯觅影·开放实验平台竞赛中心点击比赛卡片上的“开始比赛”，都可以进入算法中心。',
        order: '1',
        subCon: [
            {
                type: 'img',
                content: 'https://static.wecity.qq.com/h5/2021-9/guide1-738344ef444ca795a7757b3159c9d618.png',
            },
            {
                type: 'img',
                content: 'https://static.wecity.qq.com/h5/2021-9/guide2-754040564747563b445b148e6a16f970.png',
            },
        ],
    },

    {
        type: 'title',
        content: '报名成功的选手首次登录算法中心，会自动弹出代金券发放通知，点击“申请领取”即可领取代金券。',
        order: '2',
        subCon: [
            {
                type: 'img',
                content: 'https://static.wecity.qq.com/h5/2021-9/guide3-d60707ec615cf7b3293510ecc03a8932.png',
            },
            {
                type: 'img',
                content: 'https://static.wecity.qq.com/h5/2021-9/guide4-a060d83e71ece38bdcddb307c5f9e91f.png',
            },
        ],
    },
    {
        type: 'title',
        content: '比赛持续期间，如果选手的比赛所需资源消耗产生正常，会定期进行代金券补发，如有补发通知会实时出现在算法中心界面，请关注。',
        order: '3',
    },
];
