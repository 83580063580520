import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44fa135c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "track-details" }
const _hoisted_2 = { class: "track-details-wrap" }
const _hoisted_3 = {
  class: "t-container",
  style: {"margin-bottom":"150px"}
}
const _hoisted_4 = { class: "track-details-con" }
const _hoisted_5 = { class: "track-details-item__hd" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "text-center"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageCollection[_ctx.page], (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "track-details-item",
              key: item.order
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(item.order) + ". " + _toDisplayString(item.content), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subCon, (subItem, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "track-details-item__bd",
                  key: index
                }, [
                  (subItem.type === 'text')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(subItem.content), 1))
                    : _createCommentVNode("", true),
                  (subItem.type === 'img')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("img", {
                          src: subItem.content
                        }, null, 8, _hoisted_8)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}