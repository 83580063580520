
import { Options, Vue } from 'vue-class-component';
import * as pageCollection from '@/utils/guideContent';

@Options({
    components: {},
})
export default class userGuide extends Vue {
    private page = '';
    private pageCollection = pageCollection;
    mounted() {
        this.page = this.$route.query.page as string;
    }
}
